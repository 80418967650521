li.secondLevel,
li.firstLevel {
    padding: 0 0 0 10px;
}

.isMiniSidebar {
    .fixedSidebar:not(:hover) {

        li.secondLevel,
        li.firstLevel {
            padding-left: 0px;
        }

        .nav {
            padding-left: 6px;
        }
    }

    .fixedSidebar:hover {

        li.secondLevel,
        li.firstLevel {
            padding: 0 0 0 10px;
        }
    }
}

.font-italic {
    font-style: italic;
    color: $gray-600;
    font-weight: bolder;
}

.footer {
    padding: 15px 20px;
}

div.page-content {
    min-height: calc(100vh - 116px);
    max-height: calc(100vh - 114px);
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
}

@include media-breakpoint-down(lg) {
    div.page-content {
        min-height: calc(100vh - 142px);
        max-height: calc(100vh - 140px);
    }
}

.page-content::-webkit-scrollbar {
    width: 8px;
}

.page-content::-webkit-scrollbar-track {
    background-color: rgba($color: $gray-200, $alpha: 0.9);
    border-radius: 1px;
}

.page-content::-webkit-scrollbar-thumb {
    background-color: rgba($color: $gray-500, $alpha: 0.8);
    border-radius: 4px;
}

.page-content::-webkit-scrollbar-thumb:hover {
    width: 8px;
    background-color: rgba($color: $gray-600, $alpha: 1);
}

.profile-header,
.profile-header:hover,
.profile-header:active {
    color: #1e2a35;
    padding: 0;
    background-color: inherit;
}

.battery {
    max-height: 100px;
}

.signal {
    max-height: 100px;
    max-width: 100px;
    text-align: center;
}

.link-menu:active {
    background-color: transparent;
}

.link-menu a {
    color: $black;
    text-decoration: none;
}

.form-switch .form-check-input {
    cursor: pointer;
}

.tooltip-validation {
    max-width: 400px;
}

.breadcrumb {
    margin-bottom: 0;
}

.validationSummary {
    display: flex;
    flex-wrap: wrap;

    div {
        flex: 1 1 0px;

        @media (max-width: 1100px) and (min-width: 576px) {
            flex: 0 0 33.33%;
            margin: 0 0 20px 0;
        }

        @media (max-width: 576px) and (min-width: 320px) {
            flex: 0 0 50%;
            margin: 0 0 20px 0;
        }
    }
}

.meterSchedule {
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 2rem 0;

    .dragable-container {
        flex: 0 0 33.33%;
        padding: 0.4rem 0;
        border-radius: 0.125rem;
        border: 1px solid #dee2e6;
        border-top: none;
        overflow: auto;
        max-height: 1000px;
        min-height: 100%;
        height: 600px;
    }

    .dragable {
        background-color: $gray-100 ;
        margin: 0 0.3rem 0.3rem;
        padding: 0.5rem 1rem;
    }

    .dragable:hover {
        background-color: $gray-200 ;
    }

    .droppablecolumn {
        display: list-item;
    }
}

.horizontal-scroll {

    max-height: calc(100vh - 480px);
    min-height: 300px;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.horizontal-scroll::-webkit-scrollbar,
.ReactTable .rt-table::-webkit-scrollbar {
    height: 8px;
}

.horizontal-scroll::-webkit-scrollbar-track,
.ReactTable .rt-table::-webkit-scrollbar-track {
    background-color: rgba($color: $gray-200, $alpha: 0.9);
    border-radius: 1px;
}

.horizontal-scroll::-webkit-scrollbar-thumb,
.ReactTable .rt-table::-webkit-scrollbar-thumb {
    background-color: rgba($color: $gray-500, $alpha: 0.8);
    border-radius: 4px;
}

.horizontal-scroll::-webkit-scrollbar-:hover,
.ReactTable .rt-table::-webkit-scrollbar-thumb:hover {
    height: 8px;
    background-color: rgba($color: $gray-500, $alpha: 1);
}

.error_404 {
    font-size: 80px;
    color: $gray-500;
}

.checkboxCenter {
    display: flex;
    justify-content: center;
    margin-top: 2px;
}

.ReactTable .rt-thead .rt-th.-sort-asc {
    box-shadow: inset 0 3px 0 0 rgba($color: $black, $alpha: 0.6) !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc {
    box-shadow: inset 0 -3px 0 0 rgba($color: $black, $alpha: 0.6) !important;
}

.rightPartUpdate {
    min-height: auto;
    overflow-x: auto;
}

.leftPartUpdate {
    min-height: auto;
}

.cron-tooltip {
    color: $black;
}

.bg-gray {
    background-color: $gray-100
}



.horizontal-scroll::-webkit-scrollbar,
.dragable-container::-webkit-scrollbar {
    width: 8px;
}

.horizontal-scroll::-webkit-scrollbar-track,
.dragable-container::-webkit-scrollbar-track {
    background-color: rgba($color: $gray-200, $alpha: 0.9);
    border-radius: 1px;
}

.horizontal-scroll::-webkit-scrollbar-thumb,
.dragable-container::-webkit-scrollbar-thumb {
    background-color: rgba($color: $gray-500, $alpha: 0.8);
    border-radius: 4px;
}

.horizontal-scroll::-webkit-scrollbar-thumb:hover,
.dragable-container::-webkit-scrollbar-thumb:hover {

    width: 8px;
    background-color: rgba($color: $gray-500, $alpha: 1);
}

.lightText {
    color: $white;
}

.darkText {
    color: $black;
}


.historyPage {
    background-color: $comparison-bg-color;
}

.history {
    color: rgba($color: $gray-600, $alpha: 0.9);
}

.customizer {
    background: $white;
    position: fixed;
    height: 100%;
    width: 300px;
    top: 60px;
    right: -300px;
    z-index: 100;
    box-shadow: 0 5px 10px rgba($color: $black, $alpha: 0.5);
    transition: 0.3s ease-in;
    padding: 0.5rem 1rem;

    .service-panel-toggle {
        background: $gray-700;
        padding: 12px 20px;
        color: $white;
        position: absolute;
        top: 30%;
        cursor: pointer;
        left: -54px;
    }

    &.show-service-panel {
        right: 0px;

        .service-panel-toggle {
            display: none;
        }

    }

    .customizer-body {
        position: relative;
        height: 100%;

        .btn {
            cursor: pointer;
        }
    }

    .customizer-tab {
        display: flex;

    }
}

.custom-control-label::after,
.custom-control-label::before {
    top: 0.15rem;
}


.chart-wrapper-custom {
    max-width: 100%;
    margin: 0 auto;
    height: 350px;
    min-width: 1000px;
    overflow-x: auto;
    overflow-y: hidden;

    canvas {
        width: 100%
    }
}

.chart-wrapper-custom1 {
    width: auto !important;
    overflow-x: auto;
    min-width: 700px;
    max-width: 100%;
    overflow-y: hidden;
    cursor: crosshair;
}

.chart-1 {
    overflow-x: auto;
    min-width: 700px;
    max-width: 100%;
    overflow-y: hidden;
    cursor: crosshair;
}

.cutomeClass {
    overflow: auto hidden;
}

.cutomeClass::-webkit-scrollbar {
    height: 8px;
}

.cutomeClass::-webkit-scrollbar-track {
    background-color: rgba($color: $gray-200, $alpha: 0.9);
    border-radius: 1px;
}

.cutomeClass::-webkit-scrollbar-thumb {
    background-color: rgba($color: $gray-500, $alpha: 0.8);
    border-radius: 4px;
    cursor: pointer;
}

.cutomeClass::-webkit-scrollbar-thumb:hover {
    height: 8px;
    cursor: pointer;
    background-color: rgba($color: $gray-500, $alpha: 1);
}

.dashboard-filter-menu {
    width: 400px;
    max-width: 400px;
}

@include media-breakpoint-down(sm) {
    .dashboard-filter-menu {
        width: 300px;
        max-width: 300px;
    }
}

.daterange-text {
    font-size: 14px;
    background-color: $light;
    padding: 0 5px;
    margin-bottom: 0;
}

.label-margin {
    margin: 0;

    label {
        display: inline-block;
    }
}

@include media-breakpoint-down(md) {
    .label-margin {
        margin: 0.5rem 0;

        label {
            display: none;
        }
    }
}

.system-notification-item {
    cursor: pointer;
}

.fa-angle-down {
    transition: 0.3s;
}

.arrowIcon {
    transition: 0.3s;
    transform: rotateZ(180deg);
}

.collapse-filters {
    transition: 0.3s;
    border: 1px solid #e8e8e8;
    border-top: none;
}

.refresh-chart-icon {
    background-color: $black;
    color: $white;
    padding: 1px 3px 0;
    border-radius: 1rem;
    cursor: pointer;
    font-size: 18px;
}


.columns-dropdown {
    display: inline;
    position: relative;
    bottom: -4px;

    .dropdown-shadow {
        box-shadow: 0 0 1rem rgba($color: $black, $alpha: 0.1);
    }
}

.cke {
    border: 1px solid #4d6277;

    .cke_inner {
        background: $white;
        color: $black;

        .cke_source {
            background: $white;
            color: $black;
        }

        .cke_bottom {
            color: $black;
            background: $white;

            a.cke_path_item {
                color: $black;
            }
        }
    }
}

.cke_chrome {
    border: 1px solid #4d6277;
}

.page-loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-200;

    .wrapper {
        width: 300px;
    }

    img {
        width: 100%;
        height: auto;
    }

    .loader {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        border-radius: 1px;
        border: 2px solid #3ADF55;
        position: relative;
        padding: 3px;
        box-shadow: 0 0 5px #3ADF55;
    }

    .loader .loaderBar {
        position: absolute;
        border-radius: 1px;
        top: 0;
        right: 100%;
        bottom: 0;
        left: 0;
        background: #3ADF55;
        box-shadow: 0 5px 10px rgb(58, 223, 85);
        width: 0;
        animation: borealisBar 2s linear infinite;
    }

    @keyframes borealisBar {
        0% {
            left: 0%;
            right: 100%;
            width: 0%;
        }

        30% {
            left: 0%;
            right: 75%;
            width: 50%;
        }

        70% {
            right: 0%;
            left: 75%;
            width: 25%;
        }

        100% {
            left: 101%;
            right: 0%;
            width: 0%;
        }
    }
}

.meter-schedule-filter {
    .btn {
        border-radius: 0.3em 0 0 0.3em;
        z-index: inherit;
        background-color: $black;
        color: $white;
    }

    .show .btn {
        background-color: $black;
        color: $white;
    }

    .operator {
        background-color: rgba($color: $gray-200, $alpha: 0.9);
        color: $black;
        border: 1px solid #ced4da;
    }

    .dropdown-toggle {
        background-color: rgba($color: $gray-200, $alpha: 0.9);
        color: $black;
        border: 1px solid #ced4da;
        border-radius: 0.3em 0 0 0.3em;
    }

    .dropdown-toggle .btn:hover {
        color: $black;
    }

    .show .dropdown-toggle .btn:hover {
        color: $white;
    }

    .dropdown-toggle::after {
        margin-left: 0;
    }
}

.nav-link-wrap {
    max-width: 170px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.data-status-wrapper {
    display: flex;
    flex: 1 1 100%;
    height: 60vh;

    .leftPartDataStatus {
        border-right: 1px solid rgba($color: $black, $alpha: 0.125);
        flex-basis: content;
        max-width: 260px;
        min-width: 200px;
        padding-top: 1rem;
        height: inherit;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .rightPartDataStatus {
        flex-basis: 100%;
        overflow-x: auto;
        height: fit-content;
    }


}

@include media-breakpoint-down(md) {
    .data-status-wrapper {

        flex-direction: column;

        .leftPart {
            flex-basis: 100%;
            max-width: 100%;
            max-height: 50vh;
            overflow-y: auto;
        }

        .rightPart {
            flex-basis: 100%;
        }
    }
}


// react grid layout start

.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
}

.react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
}

.react-grid-item.cssTransforms {
    transition-property: transform;
}

.react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
}

.react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.react-grid-item>.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
}

.react-grid-item>.react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-grid-item:not(.react-grid-placeholder) {
    background: #ffff;
}

.chart-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chart-container .canvas {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: auto;
}

// react grid layout End

.table-td {
    width: 8rem;
}

.datetimeOpen {
    min-width: 23vh;
    width: min-content;
}

.role-permission {
    overflow: hidden;
}

.role-permission-label {
    width: -webkit-fill-available;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selected-row {
    background-color: #A7C7E7 !important;
}

.dark-selected-row {
    background-color: #4d6277 !important;
}


input::-ms-reveal {
    display: none;
}

.table-scroll {
    display: block;
    empty-cells: show;
    border-spacing: 0;

    thead,
    tfoot {
        position: relative;
        display: block;
        width: 100%;
    }

    tbody {
        display: block;
        position: relative;
        width: 100%;
        height: 30rem;
        overflow-y: auto;
    }

    tr {
        width: 100%;
        display: flex;
    }

    td,
    th {
        flex-basis: 100%;
        flex-grow: 2;
        display: block;
        padding: 0.5rem;
        text-align: left;
    }
}

.ql-editor {
    background-color: white;
    min-height: 100px;
}

.dark-theme .ql-editor {
    background-color: #001e3c !important;
}

.dark-theme-color {
    color: #ffff !important;
}

.selected-filter {
    width: fit-content;
}

.selected-filter-close-button {
    background-color: white;
    color: black;
}

.selected-filter-close-button:hover {
    background-color: rgb(255, 189, 173);
    color: rgb(222, 53, 11);
    cursor: pointer;
}

.dark-theme {
    .selected-filter-close-button {
        background-color: $secondary;
        color: #ffff;
    }

    .selected-filter-close-button:hover {
        background-color: rgb(255, 189, 173);
        color: rgb(222, 53, 11);
        cursor: pointer;
    }
}

.schedule-meter-data-table {
    position: relative;
    z-index: 0;
}

.report-tag {
    border: 1px solid #2962ff;
    background-color: #d3d8e8;
    border-radius: 5px;
    color: #2962ff;
    padding: 0px 5px;
    font-weight: 600;
}