@import "variables";
@import "simplebar/dist/simplebar.min.css";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.scss";
@import "./layout/sidebar";
@import "./layout/container";
@import "./layout/customizer";
@import "./layout/horizontal";
@import "./layout/topbar";
@import "./layout/rtl/rtl";
@import "custom";
@import "all/icons/font-awesome/css/fontawesome-all.css";
@import "all/icons/material-design-iconic-font/css/materialdesignicons.min.css";


div.td-dropdown-toggle div.dropdown-menu {
    min-width: 1rem !important;
}

a.a-icon-grid-action {
    color: unset;
}

div.custom-react-table [role="columnheader"].rt-th {
    padding: 10px !important;
}

.ReactTable .rt-thead .rt-tr {
    font-weight: 700;
}

.react-table-grid-header {
    padding: 10px !important;
}

.custom-sweet-alert p {
    direction: rtl;
}

.btn-secondary-info,
.btn-secondary-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.custom-sweet-alert-width-35em {
    width: 35em;
    max-width: 100%;
}

.custom-sweet-alert-width-20em {
    max-width: 20em;
}

div.on-row-click {
    cursor: pointer;
}

.breadcrumb {
    margin-bottom: 0;
}

.filter-badges {
    background: #df7f45;
    border: 2px solid #fff;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    left: 30px;
    position: absolute;
    top: 20%;
    width: 10px;
}

.button-active,
.button-active:hover {
    background-color: #2e3844;
}

.remove-padding-bottom {
    padding-bottom: 0rem !important;
}

.remove-padding-top {
    padding-top: 0rem !important;
}

.nav-tabs .nav-link.active {
    color: #fff !important;
    background-color: rgb(146, 143, 143);
    border-color: #e5eaf0 #dee2e6 #fff;
}

@media screen and (min-width: 520px) {
    .buttons-in-line {
        margin-bottom: -50px;
    }
}

.removeOverlap {
    z-index: 0;
}

.iframe-nem12 {
    width: 100%;
    min-height: calc(100vh - 116px);
}

.card-hover:hover .icon-change {
    color: #5e72e4;
}

.linkStyle {
    color: #2962ff !important;
    text-decoration: none;
}

.deleteInMeter {
    display: flex;
    justify-content: center;
    border-bottom: 0;
}

.dark {

    .sweet-alert,
    .rdtPicker {
        color: #b2b9bf !important;
        background: #323743 !important;
    }
}

.sweet-alert:not(.custom-info-alert) {
    div:first-child {
        div:first-child {
            display: none;
        }

        div:nth-child(5) {
            display: none;
        }

        div:nth-child(6) {
            display: none;
        }
    }
}

.ReactTable {
    .-pagination {

        // .-btn,
        input {
            background: unset !important;
            color: unset !important;
        }
    }
}

.card-hover {
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;

    &:hover {
        -webkit-transform: translateY(-4px) scale(1.01);
        -moz-transform: translateY(-4px) scale(1.01);
        -ms-transform: translateY(-4px) scale(1.01);
        -o-transform: translateY(-4px) scale(1.01);
        transform: translateY(-4px) scale(1.01);
        -webkit-box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
        box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
    }
}

.modal-header {
    .close {
        span:first-child {
            display: none;
        }

        box-sizing: content-box;
        width: $btn-close-width;
        height: $btn-close-height;
        padding: $btn-close-padding-y $btn-close-padding-x;
        color: $btn-close-color;
        background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat; // include transparent for button elements
        border: 0; // for button elements
        @include border-radius();
        opacity: $btn-close-opacity;

        // Override <a>'s hover style
        &:hover {
            color: $btn-close-color;
            text-decoration: none;
            opacity: $btn-close-hover-opacity;
        }

        &:focus {
            outline: 0;
            box-shadow: $btn-close-focus-shadow;
            opacity: $btn-close-focus-opacity;
        }

        &:disabled,
        &.disabled {
            pointer-events: none;
            user-select: none;
            opacity: $btn-close-disabled-opacity;
        }
    }
}

.dark {
    .modal-header {
        .close {
            background: transparent url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(255,255,255,0.9)'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") center/1em auto no-repeat !important;
        }
    }
}

.swagger-ui {
    div.information-container {
        section {
            div.info {
                margin: 0;
            }
        }
    }

    .wrapper:not(.schemes) {
        padding: 0 !important;
    }

    .response-col_description__inner {
        .renderedMarkdown {
            p {
                margin: auto;
            }
        }
    }
}

.dark {
    .swagger-ui {
        color: #e6e9ec;

        .opblock-tag,
        .dialog-ux .modal-ux-header h3,
        .info .title,
        .info p,
        a.nostyle,
        a.nostyle {
            color: #e6e9ec;
        }

        .scheme-container,
        .opblock .opblock-section-header,
        .dialog-ux .modal-ux {
            background: #001e3c;
        }

        .tab li:first-of-type:after {
            background: #b3bcc5;
        }

        section.models h4,
        .model-title,
        .opblock-description-wrapper p,
        .opblock .opblock-section-header h4,
        .btn,
        table thead tr th,
        .parameter__name,
        .parameter__type,
        table thead tr td,
        .response-col_status,
        .response-col_links,
        .tab li,
        .model,
        .dialog-ux .modal-ux-content h4,
        .dialog-ux .modal-ux-content p,
        label,
        .model-toggle.collapsed {
            color: #99a5b1;
        }

        .opblock-tag svg,
        svg:not(:root) {
            fill: #e6e9ec;
        }

        .model-toggle:after {
            background: url("../images/arrow-swagger.svg") 50% no-repeat;
        }
    }
}

.contact-bold {
    font-weight: 1000;
}

.custom-tabs-input {
    display: flex;
    position: relative;

    input {
        width: 20rem;
        padding-right: 30px;
    }

    i {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
    }
}

.td-error-div {
    position: inherit;
    margin-top: 2.5rem;
}

.modal-popup-height-30-rem {
    max-height: 34rem;
}

.tooltip {
    pointer-events: none;
}

.css-1insrsq-control :read-only {
    background-color: #eef5f9;
    color: #1e2a35;
}

.record-as-active {
    position: relative;
}

.record-as-active::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    background: #28a745;
    top: 0;
    left: 0;
}

.record-as-inactive {
    position: relative;
}


.record-as-inactive::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    background: #dc3545;
    top: 0;
    left: 0;
}

.sticky-top-remove-opacity {
    z-index: unset !important;
}

#main-wrapper-new[data-theme="light"] .rt-td.not-actual {
    background-color: rgb(255, 200, 200);
}

#main-wrapper-new[data-theme="dark"] .rt-td.not-actual {
    background-color: rgb(119, 73, 73);
}

.custom-td-width {
    width: 20%;
}

.custom-th-width {
    width: 25%;
}

.react-dropdown-tree-select .dropdown {
    .dropdown-content {
        position: inherit !important;
        width: 230px;
    }

    .dropdown-trigger.arrow {
        display: none;
    }
}

.custom-data-preview {
    overflow-y: auto;

    thead th {
        position: sticky;
        top: 0;
        z-index: 1;
        border-left: 1px solid #e8e8e8;
        border-right: 1px solid #e8e8e8;
        max-width: 30px;
        // writing-mode: vertical-lr;
        font-size: 14px;
        text-align: center;
        vertical-align: middle;
        // transform: rotateZ(180deg);
        background-color: $white;
        height: 100px;
    }

    thead th div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    thead th div span {
        transform: rotate(-90deg);
        display: block;
        white-space: nowrap;
    }

    // thead th:first-child {
    //     left: 0;
    //     z-index: 1;
    //     max-width: 150px;
    //     writing-mode: horizontal-tb;
    //     border-top: 1px inset #e8e8e8;
    //     border-bottom: 1px inset solid #e8e8e8;
    //     border-left: none;
    //     border-right: 1px inset solid #e8e8e8;
    //     background-color: $white;
    //     transform: none;


    // }

    thead th.channel-grid {
        position: sticky;
        left: 0;
        z-index: 2;
        max-width: 150px;
        writing-mode: horizontal-tb;
        // border-top: 1px inset #e8e8e8;
        border-bottom: 1px inset solid #e8e8e8;
        border-left: 1px inset solid #e8e8e8;
        border-right: 1px inset solid #e8e8e8;
        background-color: $white;
        transform: none;
    }

    thead th.nmi-grid {
        position: sticky;
        left: 150px;
        z-index: 2;
        max-width: 150px;
        writing-mode: horizontal-tb;
        border-top: 1px inset solid #e8e8e8;
        border-bottom: 1px inset solid #e8e8e8;
        border-left: 1px inset solid #e8e8e8;
        border-right: 1px inset solid #e8e8e8;
        background-color: $white;
        transform: none;
    }

    thead th:last-child {
        max-width: 150px;
        min-width: 90px;
    }

    tbody th {
        background-color: $white;
    }

    td:last-child {
        background-color: $white;
    }

    tbody tr.highlighted-tr:hover {
        border: 3px solid rebeccapurple;

        th {
            background-color: #cacaca;
        }

        td:last-child {
            background-color: #cacaca;
        }
    }

    tbody th.channel-th {
        max-width: 150px;
        position: sticky;
        left: 0;
        z-index: 1;
        line-height: 16px;
        cursor: pointer;
        font-size: 14px;
    }

    tbody th.nmi-th {
        border: 1px solid #e8e8e8;
        max-width: 150px;
        position: sticky;
        left: 150px;
        z-index: 1;
        line-height: 16px;
    }

    .custom-noData {
        background: unset !important;
        top: 80%;
        font-weight: bolder;
        color: unset !important;
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }

    tr {
        height: 5px;

        td {
            border: 1px solid #ccc;
            position: relative;
        }

        td.no-data {
            background-color: #d9534f;
        }

        td.complete-data {
            background-color: #5cb85c;
            position: relative;
        }


        td .white-stripe {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            inset: 0;
        }

        td .white-stripe>span {
            position: absolute;
            width: 100%;
            height: 100%;
            background: url("../images/white-line.svg") no-repeat;
            background-position: center;
            background-size: 100% 100%;
            transform: rotate(0deg);
            top: 0;
            left: 0;
            transform-origin: center;
        }

        td.partial-data {
            background-color: #f0ad4e;
        }

        td.complete-cumulative-data {
            background-color: #055005;
        }
    }
}

.dark {
    .custom-data-preview {
        thead th {
            color: $gray-300;
        }

        thead th:first-child {
            background-color: $dark;
        }

        tbody th:first-child {
            background-color: $dark;
            color: $gray-300;
        }
    }
}

.z-index-custom {
    z-index: 3;
}

.custom-legend {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.custom-legend li {
    float: left;
    margin-right: 15px;
    font-size: 14px;
}

.custom-legend span {
    border: 1px solid #ccc;
    float: left;
    width: 12px;
    height: 12px;
    margin: 4px;
    margin-top: 5px;
}

.custom-legend .no-data {
    background-color: #d9534f;
}

.custom-legend .complete-data {
    background-color: #5cb85c;
}

.custom-legend .partial-data {
    background-color: #f0ad4e;
}

.custom-legend .complete-cumulative-data {
    background-color: #055005;
}

.custom-normal-badge {
    font-size: 14px;
    background-color: #efbb28;
    min-width: 28px;
    height: 26px;
    padding: 6px 6px;
    color: white;
    border: 0px;
    cursor: pointer;
}

.custom-tooltip .custom-tooltip-text {
    @include reset-text();
    @include font-size($tooltip-font-size);
    @include border-radius($tooltip-border-radius);
    visibility: hidden;
    max-width: 25rem;
    text-align: left;
    position: absolute;
    right: 15px;
    top: 42px;
    word-wrap: break-word;
    padding: $tooltip-padding-y $tooltip-padding-x;
    color: $tooltip-color;
    background-color: $tooltip-bg;
    z-index: $zindex-tooltip;
}

.dark {
    .custom-tooltip .custom-tooltip-text {
        color: $gray-900;
        background-color: $gray-300;
    }
}

.custom-tooltip:hover .custom-tooltip-text {
    visibility: visible;
}

.box-shadow-dropdown.dropdown-menu.show {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.rdt .rdtPicker {
    right: 0;
}

.rdt .form-control {
    background-color: #ffffff;
}

.select-wrapper-custom {
    display: flex;
}

.select-dropdown {
    margin-right: 10px;
    position: relative;
    display: inline-block;
    width: 100%
}

.custom-iot-width {
    min-width: 100px;
}


.custom-scroll {
    height: 50vh;

    .table-responsive {
        height: inherit;
    }
}

.data-preview-datepicker .rdt .rdtPicker {
    left: 0;
}

.bar-custom-colour {
    background-color: #2962FF;
}